/* Archivo src/styles/fonts.css */
@font-face {
    font-family: 'Font';
    src: url('./EurostileNextW1G-Ext.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Font';
    src: url('./EurostileNextW1G-ExtSmBd.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
  }
  